<template>
  <div id="header">
    <!-- header section -->
    <div class="xs-top-bar top-bar-second">
      <div class="container clearfix">
        <a href="" class="xs-top-bar-mail"
          ><i class="fa fa-flag"></i><b>Special Note:</b></a
        >
        <!-- Codes by HTMLcodes.ws -->
        <marquee
          class="xs-top-bar-mail"
          behavior="scroll"
          direction="left"
          onmouseover="this.stop();"
          onmouseout="this.start();"
        >
          <div style="font-size: 15px">
            <span style="color: red; font-size: large">***</span>This website is
            under Construction. Sorry for the inconvenience. Email:
            info@yfbd.org, Phone: +880 1704-446695
          </div>
        </marquee>
      </div>
    </div>
    <header class="xs-header xs-fullWidth">
      <div class="container">
        <nav class="xs-menus">
          <div class="nav-header">
            <div class="nav-toggle"></div>
            <a href="/" class="nav-logo">
              <img
                src="https://rahimbangla.github.io/CDN/yfbd/v2/assets/logo.svg"
                alt=""
              />
            </a>
          </div>
          <div class="nav-header">
            <div class="nav-toggle"></div>
            <a href="/" class="xs-nav-logo">
              <img
                src="https://rahimbangla.github.io/CDN/yfbd/v2/assets/images/logo-m.svg"
                alt=""
              />
            </a>
          </div>
          <!-- .nav-header END -->
          <div class="nav-menus-wrapper row">
            <div class="xs-logo-wraper col-lg-3">
              <a class="nav-brand" href="/">
                <img
                  src="https://rahimbangla.github.io/CDN/yfbd/v2/assets/images/logo-m.svg"
                  alt=""
                />
              </a>
            </div>
            <!-- .xs-logo-wraper END -->
            <div class="col-lg-9">
              <ul class="nav-menu">
                <li>
                  <a href="https://youthforbangladesh.org">Home</a>
                </li>
                <!-- <li>
                  <a href="#">Donations</a>
                  <ul class="nav-dropdown">
                    <li>
                      <router-link to="/donate">
                        Donation for Zakat
                      </router-link>
                      <router-link to="/donate">
                        Donation for Children
                      </router-link>
                      <router-link to="/donate">
                        Donation for Health
                      </router-link>
                    </li>
                  </ul>
                </li> -->
                <li>
                  <a href="#">Daily Ten Members</a>
                  <ul class="nav-dropdown">
                    <li>
                      <router-link to="/dtm"> DTM Portal </router-link>
                      <router-link to="/dtm/registraion"
                        >New Registration</router-link
                      >
                      <!-- <router-link to="/dtm/paymentchecker"
                        >Payment Checker</router-link
                      > -->
                    </li>
                  </ul>
                </li>
                <!-- <li>
                  <a href="#">Volunteer</a>
                  <ul class="nav-dropdown">
                    <li>
                      <router-link to="/volunteer">
                        Volunteer Portal
                      </router-link>
                      <router-link to="/volunteer/registration"
                        >Registration</router-link
                      >
                    </li>
                  </ul>
                </li> -->
                <!-- <li>
                  <a href="#">Help</a>
                  <ul class="nav-dropdown">
                    <li>
                      <a href="https://youthforbangladesh.org/about.html"
                        >About Us</a
                      >
                    </li>
                    <li>
                      <a href="https://youthforbangladesh.org/contact.html"
                        >Contact Us</a
                      >
                    </li>
                    <li>
                      <a href="https://youthforbangladesh.org/faq.html">FAQ</a>
                    </li>
                  </ul>
                </li> -->
              </ul>
              <!-- .nav-menu END -->
            </div>
          </div>
          <!-- .nav-menus-wrapper .row END -->
        </nav>
        <!-- .xs-menus .fundpress-menu END -->
      </div>
      <!-- .container end -->
    </header>
    <!-- End header section -->
  </div>
</template>
<style>
</style>