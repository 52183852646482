<template>
  <div class="Donation donationFormPage_donation-body__7GwmC">
    <!-- <donate /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import donate from "@/components/Donate.vue";

export default {
  name: "Donation",
  components: {
    // donate,
  },
};
</script>
<style>
@media screen and (min-width: 30rem) {
  .donationFormPage_donation-body__7GwmC {
    background-image: url(https://scontent.fdac116-1.fna.fbcdn.net/v/t1.6435-9/174226221_307370547426615_8525006864337852047_n.jpg?_nc_cat=110&ccb=1-3&_nc_sid=8bfeb9&_nc_eui2=AeGit9UopSpGWa66CZD5z-eTGr1PYvwLkf8avU9i_AuR_8bAqyPpbjWCm3_wSf56grEfUsbo8GsdiMPtCdoqq665&_nc_ohc=B54nhYMTXaUAX_YEGod&_nc_ht=scontent.fdac116-1.fna&oh=3bad094b6210f2253e50e31c1226631f&oe=60AEC804);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
  }
}
.donationFormPage_donation-body__7GwmC {
  background-color: #e5e5e5;
}
</style>
