<template>
  <div class="hello">
    <div class="container">
      <div class="row">
        <div class="col">
          <img alt="Vue logo" src="favicon.svg" style="width: 120px" />
          <br /><br />
          <h1 class="hellom">{{ msg }}</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.hello {
  text-align: center;
  color: green;
  padding-top: 80px;
}
</style>
