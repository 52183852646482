import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { VueReCaptcha } from 'vue-recaptcha-v3'
require("dotenv").config();
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

 

createApp(App).use(VueSweetalert2, options).use(VueReCaptcha, { siteKey: '6LeiyCQaAAAAAGPpYpUxhk0MapV_g1UC1DQYaMU8' }).use(store).use(router).mount('#app')
